import { Grid as MuiGrid } from '@mui/material'
import { OverlayContainer } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import { styled } from '@mui/system'
import BHButton from '@bluheadless/ui/src/molecules/button'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'
import Typography from '@bluheadless/ui/src/atoms/typography'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo', 'colorHex'].includes(prop)
}

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const Subtitle = styled(Typography)`
	text-transform: uppercase;
	${({ theme }) => theme.typography.subheadline1};
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
`

const Title = styled(Typography)`
	${({ theme }) => theme.typography.headlineMedium};
`

const HeroStyled = styled(Hero)`
	height: 100%;
	display: flex;
	align-items: center;

	${OverlayContainer} {
		width: 100%;
		position: relative;
		padding: 0;
		display: flex;
		justify-content: space-between;
		margin: unset;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing(7)};
		text-align: center;
		max-width: 1000px;
		background: transparent;
		mix-blend-mode: unset;

		${({ theme }) => theme.breakpoints.up('md')} {
			gap: ${({ theme }) => theme.spacing(4)};
			text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
		}
	}
`

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: unset;
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(4)};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: ${({ theme }) => theme.spacing(2)};
	}
`

const Button = styled(BHButton, { shouldForwardProp })`
	color: ${({ colorHex }) => colorHex};
	border-color: ${({ colorHex }) => colorHex};
	min-width: 240px;
	&:hover,
	&:active,
	&:focus {
		background-color: ${({ colorHex }) => colorHex};
		border-color: ${({ colorHex }) => colorHex};
		color: ${({ theme }) => theme.palette.secondary.main};
	}
`

export { HeroStyled, Subtitle, Container, Title, CtaContainer, Button }
