import Grid from '@mui/material/Grid'
import {
	Container,
	Title,
	CtaContainer,
	Button,
	HeroStyled,
	Subtitle,
} from '@/components/ui/organisms/text-banner/text-banner.styled'

const TextBanner = ({ title, titleProps, subtitle, subtitleProps, content, cta, ctaProps, ...props }) => {
	return (
		<HeroStyled
			overlayComponent={
				<>
					<Container>
						{subtitle && (
							<Subtitle variant="subheadline1" {...subtitleProps}>
								{subtitle}
							</Subtitle>
						)}
						{title && (
							<Title variant="headlineMedium" {...titleProps}>
								{title}
							</Title>
						)}
					</Container>
					<Container>
						{cta && cta?.length > 0 && (
							<CtaContainer
								display="inline-flex"
								justifyContent={props.horizontalAlign}
								hasSiblings={!!content || !!title}
								{...ctaProps}
							>
								{cta.map(
									({ label, url, variant = 'outlined', ...rest }, key) =>
										label && (
											<Grid item key={key}>
												<Button href={url} variant={variant} {...rest}>
													{label}
												</Button>
											</Grid>
										)
								)}
							</CtaContainer>
						)}
					</Container>
				</>
			}
			{...props}
		/>
	)
}

export default TextBanner
